<template>
    <div class="task-postit" :class="{ completed: task.completed }">
      <input type="text" v-model="editableTask.title" @blur="updateTask" class="task-title"/>
      <textarea v-model="editableTask.description" @blur="updateTask" @input="autoGrow" ref="textArea"></textarea>
      <div class="due-date">Échéance :<input type="date" v-model="editableTask.dueDate" @change="updateTask" /></div>
      <div v-if="task.completed" class="completed-date">
        Terminé le {{ displayDate(task.completedDate) }}
      </div>
      <button @click="toggleComplete" class="action-button">
        <i :class="task.completed ? 'fas fa-undo-alt' : 'fas fa-check'"></i>
      </button>
      <button @click="deleteTask(task.id)" class="action-button">
        <i class="fas fa-trash"></i>
      </button>
    </div>
</template>

<script>
export default {
  name: "TaskPostit",
  props: ["task"],
  data() {
    return {
      editableTask: { ...this.task },
    };
  },
  methods: {
    updateTask() {
      this.$emit("update-task", this.editableTask);
      this.$nextTick(() => this.autoGrow());
    },
    deleteTask(taskId) {
      if (window.confirm("Êtes-vous sûr de vouloir supprimer cette tâche ?")) {
        this.$emit("delete-task", taskId);
      }
    },
    toggleComplete() {
      this.editableTask.completed = !this.editableTask.completed;
      if (this.editableTask.completed) {
        this.editableTask.completedDate = new Date().toISOString().substr(0, 10);
      } else {
        this.editableTask.completedDate = null;
      }
      this.updateTask();
    },
    displayDate(date) {
      if (!date) return '';
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    autoGrow() {
      const textArea = this.$refs.textArea;
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    }
  },
  mounted() {
    this.autoGrow();
  }
};
</script>
  
  <style scoped>
  .task-postit {
    background-color: #f8fd69ef;
    border: 1px solid #ffd700;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 10px;
    width: 200px;
    height: auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
  }
  
  .task-postit:hover {
    transform: translateY(-5px);
  }

  .task-title {
  font-weight: bold;
}
  
  .completed {
    opacity: 0.8;
  }
  
  .completed-date {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 0, 0);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .action-button {
    margin-top: 5px;
    background-color: #ff6347;
    border: none;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .action-button i {
    margin-right: 5px;
  }
  
  .action-button:hover {
    background-color: #ff4500;
  }
  
  input[type="text"],
  textarea,
  input[type="date"] {
    background-color: #f8fd69ef;
    border: none;
    outline: none;
    padding: 4px;
  }
  
  textarea {
    min-height: 50px; /* Hauteur initiale */
    height: auto; /* Permet l'ajustement automatique */
    overflow: hidden; /* Empêche la barre de défilement */
  }
  </style>
  