<template>
  <div id="app">
    <header>
      <h1>ToDoList</h1>
    </header>
    <HomeView :tasks="tasks" @update-tasks="updateTasks" />
    <Footer />
  </div>
</template>

<script>
import { ref } from "vue";
import HomeView from "./views/HomeView.vue";
import Footer from "./components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    HomeView,
    Footer
  },
  setup() {
    const tasks = ref([]);

    const updateTasks = (updatedTasks) => {
      tasks.value = updatedTasks;
    };

    return {
      tasks,
      updateTasks,
    };
  },
};
</script>

<style>
#app {
  text-align: center;
  color: #333;
}

header {
  background-color: #42b983;
  color: white;
  padding: 20px;
  font-size: 24px;
}
</style>
