  <template>
        <footer class="footer">
      <p>&copy; 2024 Julien SEITE.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent'
  };
  </script>
  
  <style scoped>
    .footer {
    background-color: #42b983;
    color: white;
    padding: 10px 0;
    text-align: center;
  }
  </style>
  