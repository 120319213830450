<template>
    <div class="home">
      <button @click="showAddTaskForm = !showAddTaskForm" class="toggle-form-button">
        <i :class="showAddTaskForm ? 'fas fa-minus' : 'fas fa-plus'"></i>
      </button>
      <transition name="fade">
        <div v-if="showAddTaskForm" class="add-task-form">
          <input type="text" v-model="newTask.title" placeholder="Titre de la tâche" />
          <textarea v-model="newTask.description" placeholder="Description de la tâche"></textarea>
          <input type="date" v-model="newTask.dueDate" placeholder="Échéance" />
          <button @click="addTask"><i class="fas fa-plus"></i> Ajouter</button>
        </div>
      </transition>
      <div class="task-board">
        <div v-for="task in sortedTasks" :key="task.id" :class="{ completed: task.completed }">
          <TaskPostit
            :task="task"
            @delete-task="deleteTask"
            @update-task="updateTask"
            @toggle-complete="toggleComplete"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import TaskPostit from '../components/TaskPostit.vue';
  
  export default {
    name: 'HomeView',
    components: {
      TaskPostit
    },
    data() {
      return {
        tasks: [],
        newTask: {
          title: '',
          description: '',
          dueDate: '',
          completed: false
        },
        showAddTaskForm: false
      };
    },
    computed: {
      sortedTasks() {
        return [...this.tasks].sort((a, b) => {
          if (a.completed === b.completed) {
            return new Date(a.dueDate) - new Date(b.dueDate);
          }
          return a.completed ? 1 : -1;
        });
      }
    },
    created() {
      this.loadTasks();
    },
    methods: {
      loadTasks() {
        const tasksFromStorage = localStorage.getItem('tasks');
        if (tasksFromStorage) {
          this.tasks = JSON.parse(tasksFromStorage);
        }
      },
      addTask() {
        if (this.newTask.title && this.newTask.dueDate) {
          const newTask = {
            ...this.newTask,
            id: Date.now(),
            completed: false
          };
          this.tasks.push(newTask);
          this.resetNewTask();
          this.showAddTaskForm = false;
          this.saveTasks();
        }
      },
      resetNewTask() {
        this.newTask = { title: '', description: '', dueDate: '', completed: false };
      },
      deleteTask(taskId) {
        this.tasks = this.tasks.filter(task => task.id !== taskId);
        this.saveTasks();
      },
      updateTask(updatedTask) {
        const index = this.tasks.findIndex(task => task.id === updatedTask.id);
        if (index !== -1) {
          this.tasks.splice(index, 1, updatedTask);
          this.saveTasks();
        }
      },
      toggleComplete(taskId) {
        const task = this.tasks.find(task => task.id === taskId);
        if (task) {
          task.completed = !task.completed;
          this.saveTasks();
        }
      },
      saveTasks() {
        localStorage.setItem('tasks', JSON.stringify(this.tasks));
      }
    }
  };
  </script>
  
  <style>
  .home {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .toggle-form-button {
    margin-bottom: 10px;
    background-color: #42b983;
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .toggle-form-button:hover {
    background-color: #369b7a;
  }
  
  .add-task-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .add-task-form input, .add-task-form textarea {
    margin: 5px 0;
    padding: 8px;
  }
  
  .add-task-form button {
    padding: 8px;
    background-color: #42b983;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .add-task-form button i {
    margin-right: 5px;
  }
  
  .add-task-form button:hover {
    background-color: #369b7a;
  }
  
  .task-board {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .completed {
    opacity: 0.7;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  